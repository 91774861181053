// pages/login.js
/*
 * This page serves as a dedicated route for handling login redirection in the application.
 * It is necessary due to the way Auth0 handles application login URIs.
 *
 * Auth0 requires an Application Login URI that points to a route in the application
 * capable of redirecting to Auth0's /authorize endpoint. This setup ensures that
 * users who might bookmark the login page, complete password resets, or verify their
 * email addresses can be appropriately redirected to the login flow.
 *
 * In this implementation, we leverage the Next.js useEffect hook to perform the redirection
 * to /api/auth/login when the component mounts. This initiates the login process via Auth0,
 * maintaining a seamless authentication experience for the user.
 */
import { useEffect } from "react";
import { useRouter } from "next/router";

const Login = () => {
	const router = useRouter();

	useEffect(() => {
		// Redirect to /api/auth/login
		router.push("/api/auth/login");
	}, [router]);

	return null; // This renders nothing, as the page just handles login logic
};

export default Login;
